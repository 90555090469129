<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <hr>
      <b-card header-tag="header">
        <template
            #header
            class="card-header"
        >
          <b-row>
            <b-col cols="12">
              <b-button
                  :variant="
                  $store.state.rezervasyon.rezervasyonBilgileri.checkOut == 0
                    ? 'cardTitle-L'
                    : 'bgDanger'
                "
                  disabled
                  class="opacity-none"
              >
                <feather-icon
                    :icon="
                    $store.state.rezervasyon.rezervasyonBilgileri.checkOut == 0
                      ? 'CheckIcon'
                      : 'ArchiveIcon'
                  "
                    class="mr-50 text-white"
                />
                <span class="align-middle text-white">{{
                    $store.state.rezervasyon.rezervasyonBilgileri.checkOut == 0
                        ? 'Aktif'
                        : 'Arşiv'
                  }}</span>
              </b-button>
              <b-form-group :class="width < 298 ? 'mt-50':''">
                <v-select
                    v-model="currentOdaKodu"
                    :components="{ OpenIndicator, Deselect }"
                    label="odaKodu"
                    :options="$store.state.rezervasyon.rezervasyonOdaListesi"
                    @input="rezGo"
                >
                  <template #no-options>
                    <p>Sonuç Bulunamadı</p>
                  </template>
                </v-select>
              </b-form-group>
              <b-button
                  variant="outline-primary"
                  disabled
                  :class="width < 412 ? 'mt-50':''"
              >
                <feather-icon
                    icon="HashIcon"
                    class="mr-50"
                />
                <span class="align-middle">{{
                    $store.state.rezervasyon.rezervasyonBilgileri.rezervasyonNo
                  }}</span>
              </b-button>
              <b-button
                  variant="outline-primary"
                  disabled
                  :class="width < 487 ? 'mt-50':''"
              >
                <feather-icon
                    icon="UsersIcon"
                    class="mr-50"
                />
                <span class="align-middle">{{
                    $store.state.rezervasyon.rezervasyonBilgileri.adult
                  }}</span>
              </b-button>
              <b-button
                  variant="outline-primary"
                  disabled
                  :class="width < 602 ? 'mt-50':''"
              >
                <feather-icon
                    icon="HomeIcon"
                    class="mr-50"
                />
                <span
                    class="align-middle"
                >{{
                    $store.state.rezervasyon.rezervasyonBilgileri.period
                  }}
                  Gece</span>
              </b-button>
              <b-button
                  variant="outline-primary"
                  disabled
                  :class="width < 911 ? 'mt-50':''"
              >
                <feather-icon
                    icon="CalendarIcon"
                    class="mr-50"
                />
                <span
                    class="align-middle"
                >Giriş:
                  {{ $store.state.rezervasyon.rezervasyonBilgileri.girisTarih }}
                  / Çıkış:
                  {{
                    $store.state.rezervasyon.rezervasyonBilgileri.cikisTarih
                  }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row :class="width < 1039 ? 'mt-2':''">
            <b-form-checkbox
                v-model="odaStatus"
                class="custom-control-primary"
                switch
                @input="odaStatusChange"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon"/>
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon"/>
              </span>
            </b-form-checkbox>
            <h5>Oda Kapatma</h5>
          </b-row>
          <b-input-group class="mt-2">
            <b-form-input
                v-model="odaMessage"
                placeholder="Rezervasyon Notu"
                autocomplete="off"
                debounce="400"
            />
          </b-input-group>
        </template>
        <b-row>
          <table
              class="table table-hover table-striped table-borderless"
              :class="width < 1400 ? ['table-responsive'] : '' "
              :style="{minHeight: width < 1400 ? '240px!important':''}"
          >
            <thead>
            <tr style="white-space: nowrap !important">
              <th
                  width="30%"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                KONUK BİLGİLERİ
              </th>
              <th
                  width="10%"
                  class="text-center"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                GİRİŞ
              </th>
              <th
                  width="10%"
                  class="text-center"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                ÇIKIŞ
              </th>
              <th
                  width="6%"
                  class="text-center"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                KONAKLAMA
              </th>
              <th
                  width="10%"
                  class="text-center"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                GÜNLÜK ÜCRET
              </th>
              <th
                  v-show="devirControl"
                  width="4%"
                  class="text-center"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                DEVİR
              </th>
              <th
                  width="10%"
                  class="text-center"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                TOPLAM ÜCRET
              </th>
              <th
                  width="20%"
                  class="text-center"
                  scope="col"
                  :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
              >
                İŞLEMLER
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(rezervasyon, index) in salaryListItems"
                :key="index"
            >
              <td>
                <div class="d-flex align-items-center">
                  <b-dropdown
                      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                      text="Info"
                      variant="flat-info"
                      no-caret
                  >
                    <template #button-content>
                      <feather-icon
                          icon="MenuIcon"
                          size="20"
                          class="avatar-side-icon"
                          :class="{'text-warning':rezervasyon.checkOut == 1}"
                      />
                    </template>
                    <b-dropdown-item
                        @click="kartOlustur(rezervasyon)"
                    >
                      Konaklama Kartı Oluştur
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-b-modal.oda-degistir
                        :disabled="rezervasyon.checkOut == 1"
                        @click="rezEvident(rezervasyon)"
                    >
                      Oda Değiştir
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-b-modal.konuk-bilgileri
                        :disabled="rezervasyon.checkOut == 1"
                        @click="rezEvident(rezervasyon)"
                    >
                      Konuk Bilgilerini Düzenle
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-b-modal.konuk-degistir
                        :disabled="rezervasyon.checkOut == 1"
                        @click="rezEvident(rezervasyon)"
                    >
                      Konuk Değiştir
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="rezervasyon.onRezervasyon == 0"
                        v-b-modal.tahsilat-kayit
                        @click="rezEvident(rezervasyon)"
                    >
                      Tahsilat Kayıtları
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="rezervasyon.checkOut == 0"
                        v-b-modal.mukerrer-kayit
                        @click="rezEvident(rezervasyon)"
                    >
                      Mükerrer Kayıt
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="rezervasyon.checkOut == 1"
                        class="bg-success bg-lighten-2 text-white"
                        @click="backCheckOut(rezervasyon)"
                    >
                      Check Out Geri Al
                    </b-dropdown-item>
                    <b-dropdown-item @click="faturaModal(rezervasyon)">
                      Fatura Oluştur
                    </b-dropdown-item>
                    <b-dropdown-item v-if="rezervasyon.faturaID != null" @click="faturaShow(rezervasyon.faturaID)">
                      Faturayı Görüntüle
                      <feather-icon icon="StarIcon" class="ml-50" style="color:orange;"/>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-avatar
                      v-b-tooltip.hover.top="
                        rezervasyon.adi + ' ' + rezervasyon.soyAdi
                      "
                      class="pull-up mr-2"
                      :style="{
                        backgroundColor:
                          rezervasyon.cinsiyet == 1
                            ? $store.getters.bgInfo
                            : $store.getters.bgDanger,
                      }"
                      :text="
                        rezervasyon.adi ? rezervasyon.adi.charAt(0) : ' ' +
                          '.' +
                          rezervasyon.soyAdi ? rezervasyon.soyAdi.charAt(0) : ' '
                      "
                      badge=""
                      badge-variant="success"
                      variant="primary"
                  />
                  <div class="d-flex flex-column">
                      <span class="fw-bolder userAdi">
                        <p class="konukIsim">
                          {{ rezervasyon.adi }} {{ rezervasyon.soyAdi }}
                        </p>
                        <span
                            v-if="rezervasyon.onlineRezervasyon == 1"
                            style="
                              background-color: #6f3131 !important;
                              color: white !important;
                            "
                            class="badge badge-light-danger onRezBadge"
                        >Online Rezervasyon</span>
                        <span
                            v-if="rezervasyon.onRezervasyon == 1"
                            style="
                              background-color: #696f31 !important;
                              color: white !important;
                            "
                            class="badge badge-light-danger onRezzBadge"
                        >Ön Rezervasyon</span>
                        <span
                            v-if="rezervasyon.checkOut == 1"
                            style="background-color: #696f31 !important"
                            class="badge badge-bgWarning cikisRezBadge"
                        >Çıkış Yapıldı</span>
                      </span>
                    <small class="emp_post text-muted myTelBadge">
                        <span class="badge badge-light-primary smallBadge">{{
                            rezervasyon.tarifeText
                          }}</span>
                      <span
                          class="badge badge-light-primary smallBadge"
                      >TC: {{ rezervasyon.tcKimlik }}</span>
                      <b-badge
                          variant="primary"
                          class="badge-light-primary"
                      >
                        <feather-icon
                            icon="PhoneIcon"
                            class="mr-25"
                        />
                        <span>{{ rezervasyon.tel }}</span>
                      </b-badge>
                    </small>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <b-button
                    v-ripple.400="'rgba(130, 134, 139, 0.2)'"
                    v-b-modal.giris-tarih
                    variant="flat-primary"
                    class="inputTarih"
                    :disabled="
                      rezervasyon.onlineRezervasyon == 1
                        ? true
                        : false || rezervasyon.checkOut == 1
                          ? true
                          : false
                    "
                    @click="rezEvident(rezervasyon)"
                >
                  {{ rezervasyon.girisTarih }}
                </b-button>
              </td>
              <td class="text-center">
                <b-button
                    v-ripple.400="'rgba(130, 134, 139, 0.2)'"
                    v-b-modal.cikis-tarih
                    variant="flat-primary"
                    class="inputTarih"
                    style="white-space: nowrap"
                    :disabled="
                      rezervasyon.onlineRezervasyon == 1
                        ? true
                        : false || rezervasyon.checkOut == 1
                          ? true
                          : false
                    "
                    @click="rezEvident(rezervasyon)"
                >
                  {{ rezervasyon.cikisTarih }}
                </b-button>
              </td>
              <td class="text-center">
                <b-badge
                    pill
                    variant="cardTitle-L"
                    class="pVerilcek"
                >{{ rezervasyon.period }} Gece
                </b-badge>
              </td>
              <td class="text-center">
                <b-badge
                    v-b-modal.fiyat-guncelle
                    href="#"
                    pill
                    variant="cardTitle-L"
                    class="pVerilcek"
                    :disabled="
                      rezervasyon.onlineRezervasyon == 1 ? true : false
                    "
                    @click="rezEvident(rezervasyon)"
                >
                  {{ rezervasyon.fiyat }} TL
                </b-badge>
              </td>
              <td
                  v-show="devirControl"
                  class="text-center"
              >
                <b-badge
                    pill
                    variant="cardTitle-L"
                    class="pVerilcek"
                >{{ rezervasyon.devir }} TL
                </b-badge>
              </td>
              <td class="text-center">
                <b-badge
                    v-b-modal.toplam-fiyat-guncelle
                    pill
                    variant="cardTitle-L"
                    class="pVerilcek"
                    :disabled="
                      rezervasyon.onlineRezervasyon == 1 ? true : false
                    "
                    @click="rezEvident(rezervasyon)"
                >{{ rezervasyon.toplamFiyatInt }} TL
                </b-badge>
              </td>
              <td>
                <b-row class="d-flex justify-content-center actionsButton">
                  <b-button
                      v-if="rezervasyon.onRezervasyon == 1"
                      v-b-modal.onrez-check-in
                      class="mr-50 border-0"
                      variant="bgPrimary"
                      size="sm"
                      :style="{backgroundColor:$store.getters.bgSuccess,color:'white'}"
                      @click="rezEvident(rezervasyon)"
                  >
                    <feather-icon
                        icon="LogInIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">CheckIn</span>
                  </b-button>
                  <b-button
                      v-if="rezervasyon.onlineRezervasyon == 0"
                      v-show="rezervasyon.onRezervasyon == 0"
                      v-b-modal.tahsilat-kayit
                      class="text-white mr-50 border-0"
                      variant="cardTitle-L"
                      size="sm"
                      :style="{backgroundColor:$store.getters.bgInfo,color:'white'}"
                      @click="rezEvident(rezervasyon)"
                  >
                    <feather-icon
                        icon="CreditCardIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Tahsilat</span>
                  </b-button>
                  <b-button
                      v-if="rezervasyon.onlineRezervasyon == 1"
                      v-b-modal.tahsilat-kayit-success
                      variant="cardTitle-L"
                      size="sm"
                      class="text-white mr-50 border-0"
                      @click="rezEvident(rezervasyon)"
                  >
                    <feather-icon
                        icon="CreditCardIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Tahsilat</span>
                  </b-button>
                  <b-button
                      v-if="rezervasyon.odemeDurum == 1"
                      v-show="rezervasyon.checkOut == 0"
                      v-b-modal.check-out
                      class="mr-50 border-0"
                      variant="bgInfo"
                      size="sm"
                      :style="{backgroundColor:$store.getters.bgWarning,color:'white'}"
                      @click="rezEvident(rezervasyon)"
                  >
                    <feather-icon
                        icon="LogInIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">CheckOut</span>
                  </b-button>
                  <b-button
                      v-if="rezervasyon.odemeDurum == 0"
                      v-show="rezervasyon.onRezervasyon == 0"
                      class="text-white mr-50 border-0"
                      size="sm"
                      variant="bgDanger"
                      :disabled="rezervasyon.onRezervasyon == 0"
                      :style="{backgroundColor:$store.getters.bgInfo,color:'white'}"
                  >
                    <feather-icon
                        icon="LogInIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Tahsilat Bek.</span>
                  </b-button>
                  <b-button
                      v-if="rezervasyon.onRezervasyon == 1"
                      v-b-modal.mukerrer-kayit
                      class="mr-50 border-0"
                      variant="bgDanger"
                      size="sm"
                      :style="{backgroundColor:$store.getters.bgDanger,color:'white'}"
                      @click="rezEvident(rezervasyon)"
                  >
                    <feather-icon
                        icon="LogInIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Kaydı Sil</span>
                  </b-button>
                  <b-button
                      v-if="rezervasyon.checkOut == 1"
                      size="sm"
                      variant="bgWarning"
                      class="text-white mr-50 border-0"
                      :disabled="rezervasyon.checkOut == 1"
                      :style="{backgroundColor:$store.getters.bgWarning,color:'white'}"
                  >
                    <feather-icon
                        icon="LogInIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Çıkış Yapıldı</span>
                  </b-button>
                </b-row>
              </td>
            </tr>
            </tbody>
          </table>
          <b-col cols="12"/>
        </b-row>
        <hr
            v-if="
            this.$store.state.rezervasyon.rezervasyonBilgileri.odaKapatma == 1
              ? false
              : true
          "
        >
        <b-row
            v-if="$store.state.rezervasyon.rezervasyonBilgileri.odaKapatma != 1"
            :class="width <1380 ?'d-none':''"
        >
          <b-col>
            <b-form-input
                id="basicInput"
                v-model="addRezervasyon.isim"
                autocomplete="off"
                placeholder="İsim"
            />
          </b-col>
          <b-col>
            <b-form-input
                id="basicInput"
                v-model="addRezervasyon.soyIsim"
                autocomplete="off"
                placeholder="Soyisim"
            />
          </b-col>
          <b-col>
            <b-input-group>
              <b-form-input
                  id="basicInput"
                  v-model.number="addRezervasyon.tcKimlik"
                  autocomplete="off"
                  placeholder="TC Kimlik"
                  :state="isTc"
              />
              <b-form-valid-feedback tooltip>
                <a
                    href="javascript:void(0)"
                    class="text-white"
                    @click="newUserAtama"
                >
                  {{ isTcUserName }}
                </a>
              </b-form-valid-feedback>
            </b-input-group>
          </b-col>
          <b-col>
            <b-form-input
                id="basicInput"
                v-model="addRezervasyon.tel"
                autocomplete="off"
                placeholder="Telefon"
            />
          </b-col>
          <b-col>
            <b-form-select
                v-model="addRezervasyon.cinsiyet"
                :options="addRezervasyon.gender"
            />
          </b-col>
          <b-col>
            <b-form-select
                v-model="addRezervasyon.rezervasyon"
                :options="addRezervasyon.reservation"
            />
          </b-col>
          <b-col>
            <b-form-select
                v-model="addRezervasyon.tarife"
                value-field="tarifeID"
                text-field="tarifeAdi"
            >
              <b-form-select-option
                  v-for="(tarife, index) in tarifeOps"
                  :key="index"
                  :value="tarife.tarifeID"
              >
                {{ tarife.tarifeAdi }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col>
            <b-form-input
                id="basicInput"
                v-model="addRezervasyon.ucret"
                v-numericOnly
                autocomplete="off"
                placeholder="Ücret"
                step="0.0001"
                type="number"
            />
          </b-col>
          <b-col>
            <flat-pickr
                v-model="addRezervasyon.girisTarih"
                class="form-control"
                placeholder="Giriş Tarihi"
            />
          </b-col>
          <b-col>
            <flat-pickr
                v-model="addRezervasyon.cikisTarih"
                class="form-control"
                placeholder="Çıkış Tarihi"
            />
          </b-col>
          <b-col>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                class="border-0"
                :style="{backgroundColor:$store.getters.bgSuccess}"
                @click="konukKayit"
            >
              Kaydet
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="$store.state.rezervasyon.rezervasyonBilgileri.odaKapatma != 1 && width < 1380">
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <b-form-input
                id="basicInput"
                v-model="addRezervasyon.isim"
                autocomplete="off"
                placeholder="İsim"
            />
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <b-form-input
                id="basicInput"
                v-model="addRezervasyon.soyIsim"
                autocomplete="off"
                placeholder="Soyisim"
            />
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <b-input-group>
              <b-form-input
                  id="basicInput"
                  v-model.number="addRezervasyon.tcKimlik"
                  autocomplete="off"
                  placeholder="TC Kimlik"
                  :state="isTc"
              />
              <b-form-valid-feedback tooltip>
                <a
                    href="javascript:void(0)"
                    class="text-white"
                    @click="newUserAtama"
                >
                  {{ isTcUserName }}
                </a>
              </b-form-valid-feedback>
            </b-input-group>
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <b-form-input
                id="basicInput"
                v-model="addRezervasyon.tel"
                autocomplete="off"
                placeholder="Telefon"
            />
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <b-form-select
                v-model="addRezervasyon.cinsiyet"
                :options="addRezervasyon.gender"
            />
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <b-form-select
                v-model="addRezervasyon.rezervasyon"
                :options="addRezervasyon.reservation"
            />
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <b-form-select
                v-model="addRezervasyon.tarife"
                value-field="tarifeID"
                text-field="tarifeAdi"
            >
              <b-form-select-option
                  v-for="(tarife, index) in tarifeOps"
                  :key="index"
                  :value="tarife.tarifeID"
              >
                {{ tarife.tarifeAdi }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <flat-pickr
                v-model="addRezervasyon.girisTarih"
                class="form-control"
                placeholder="Giriş Tarihi"
            />
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <flat-pickr
                v-model="addRezervasyon.cikisTarih"
                class="form-control"
                placeholder="Çıkış Tarihi"
            />
          </b-col>
          <b-col
              lg="2"
              md="3"
              sm="12"
              class="p-25"
          >
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                class="border-0"
                :style="{backgroundColor:$store.getters.bgSuccess}"
                @click="konukKayit"
            >
              Kaydet
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-modal
        id="oda-degistir"
        no-close-on-backdrop
        title="Oda Değiştir"
        ok-title="Güncelle!"
        ok-variant="bgInfo"
        cancel-variant="outline-bgDanger"
        centered
        cancel-title="İptal"
        @ok="odaDegistir"
    >
      <div class="oda-durum-modal">
        <b-form>
          <b-form-group
              label="Yeni Oda"
              label-for="vue-select"
          >
            <v-select
                v-model="odaSelect"
                label="odaKodu"
                :options="$store.state.rezervasyon.odaListesi"
                class="modalSelect"
            />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
    <b-modal
        id="konuk-bilgileri"
        no-close-on-backdrop
        ok-variant="bgInfo"
        ok-title="Değişiklikleri Kaydet"
        cancel-variant="bgDanger"
        centered
        cancel-title="Kapat"
        header-bg-variant="white"
        size="lg"
        @ok="konukGuncelle"
        @hide="chooseRezervasyon = []"
    >
      <template #modal-header>
        <div class="w-100 d-block mt-3">
          <h1 class="text-center">
            Konuk Bilgilerini Düzenle
          </h1>
          <p class="text-center">
            *Bu işlem konuk değiştirmez, mevcut konuğun bilgilerini düzenler.
          </p>
        </div>
      </template>
      <div class="oda-durum-modal">
        <b-form class="p-3">
          <b-row>
            <b-col cols="6">
              <b-form-group label="İsim">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.adi"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Soy İsim">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.soyAdi"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="TC Kimlik:">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.tcKimlik"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Cinsiyet">
                <v-select
                    v-model="cinsiyet"
                    class="modalSelect"
                    :options="gender"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Tarife">
                <b-form-select
                    v-model="chooseRezervasyon.tarife"
                    :options="tarifeOps"
                    value-field="tarifeID"
                    text-field="tarifeAdi"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Telefon">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.tel"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Oto Plaka">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.otoPlaka"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Ücret">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.fiyat"
                    v-numericOnly
                    disabled
                    step="0.0001"
                    type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
    <b-modal
        id="konuk-degistir"
        no-close-on-backdrop
        ok-variant="bgInfo"
        ok-title="Kaydet"
        cancel-variant="bgDanger"
        centered
        cancel-title="Kapat"
        header-bg-variant="white"
        size="lg"
        @ok="konukChange"
        @hide="modalChangeHide"
    >
      <template #modal-header>
        <div class="w-100 d-block mt-3">
          <h1 class="text-center">
            Konuk Değiştir
          </h1>
          <p class="text-center">
            * Dikkat! Bu form mevcut konaklama kaydını yeni konaklayıcı ile
            değiştirmek için kullanılır. Ayrıca bir konaklama kaydı oluşturmaz.
          </p>
        </div>
      </template>
      <div class="oda-durum-modal">
        <b-form class="p-3">
          <b-row>
            <b-col cols="6">
              <b-form-group label="İsim">
                <b-form-input
                    id="basicInput"
                    v-model="konukDegistir.adi"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Soy İsim">
                <b-form-input
                    id="basicInput"
                    v-model="konukDegistir.soyAdi"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="TC Kimlik:">
                <b-form-input
                    id="basicInput"
                    v-model="konukDegistir.tcKimlik"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Cinsiyet">
                <v-select
                    v-model="konukDegistir.cinsiyet"
                    class="modalSelect"
                    :options="konukDegistir.gender"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Tarife">
                <b-form-select
                    v-model="konukDegistir.tarife"
                    :options="tarifeOps"
                    value-field="tarifeID"
                    text-field="tarifeAdi"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Telefon">
                <b-form-input
                    id="basicInput"
                    v-model="konukDegistir.tel"

                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Oto Plaka">
                <b-form-input
                    id="basicInput"
                    v-model="konukDegistir.otoPlaka"
                    autocomplete="off"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
    <b-modal
        id="tahsilat-kayit"
        ok-variant="bgInfo"
        ok-title="Değişiklikleri Kaydet"
        cancel-variant="bgDanger"
        cancel-title="Kapat"
        size="lg"
        title="Tahsilat Kayıtları"
        hide-footer
        @hide="tahsilatHide"
    >
      <div>
        <b-row class="mt-2">
          <b-col
              lg="4"
              sm="6"
          >
            <statistic-card-horizontal
                icon="DollarSignIcon"
                :statistic="chooseRezervasyon.toplamFiyat + ' TL'"
                statistic-title="Toplam Ücret"
                class="tahsilat-card"
            />
          </b-col>
          <b-col
              lg="4"
              sm="6"
          >
            <statistic-card-horizontal
                icon="UserCheckIcon"
                color="success"
                :statistic="chooseRezervasyon.toplamTahsilat + ' TL'"
                statistic-title="Tahsil Edilen"
                class="tahsilat-card"
            />
          </b-col>
          <b-col
              lg="4"
              sm="6"
          >
            <statistic-card-horizontal
                icon="CreditCardIcon"
                color="danger"
                :statistic="chooseRezervasyon.kalanBakiye + ' TL'"
                statistic-title="Kalan Ücret"
                class="tahsilat-card"
            />
          </b-col>
        </b-row>
        <hr>
        <b-tabs
            content-class="mt-2"
            justified
        >
          <b-tab
              active
              :disabled="chooseRezervasyon.checkOut == 1"
          >
            <template #title>
              <feather-icon icon="EditIcon"/>
              <span>Tahsilat Yap</span>
            </template>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Tahsilat Yöntemi">
                  <b-form-select
                      v-model="tahsilat.chooseTur"
                      :options="tahsilat.tahsilatTur"
                      value="nakit"
                      value-field="tur"
                      text-field="title"
                  />
                  <!--                  <v-select-->
                  <!--                      v-model="tahsilat.chooseTur"-->
                  <!--                      label="title"-->
                  <!--                      :options="tahsilat.tahsilatTur"-->
                  <!--                      class="modalSelect"-->
                  <!--                  />-->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Tutar"
                    label-for="basicInput"
                >
                  <b-form-input
                      v-model="tahsilat.tutar"
                      v-numericOnly
                      step="0.0001"
                      number
                      type="number"
                      autocomplete="off"
                      @keyup.enter="tahsilatYap"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Fiş NO"
                    label-for="basicInput"
                >
                  <b-form-input
                      v-model="tahsilat.fisNo"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="float-right mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess,color:'white'}"
                  @click="tahsilatYap"
              >
                Tahsilat Yap
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgDanger"
                  class="border-0"
                  :style="{backgroundColor:$store.getters.bgDanger,color:'white'}"
                  @click="$bvModal.hide('tahsilat-kayit')"
              >
                Kapat
              </b-button>
            </b-row>
          </b-tab>
          <b-tab :disabled="chooseRezervasyon.checkOut == 1">
            <template #title>
              <feather-icon icon="UserIcon"/>
              <span>Başka Hesaba Aktar</span>
            </template>
            <b-row>
              <b-col cols="12">
                <b-form-group
                    label="TC Kimlik / Vergi Kimlik Numarası"
                    label-for="basicInput"
                >
                  <b-form-input v-model="otherTahsilat.tcKimlik"/>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Tutar"
                    label-for="basicInput"
                >
                  <b-form-input
                      v-model="otherTahsilat.tutar"
                      v-numericOnly
                      step="0.0001"
                      type="number"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                    label="Fiş NO"
                    label-for="basicInput"
                >
                  <b-form-input
                      v-model="otherTahsilat.fisNo"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="float-right mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess,color:'white'}"
                  @click="tahsilatYapBaskaHesap"
              >
                Tahsilat Yap
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgDanger"
                  class="border-0"
                  :style="{backgroundColor:$store.getters.bgDanger,color:'white'}"
                  @click="$bvModal.hide('tahsilat-kayit')"
              >
                Kapat
              </b-button>
            </b-row>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Tahsilat Kayıtları</span>
            </template>
            <table class="table table-responsive-md">
              <thead>
              <tr>
                <th
                    scope="col"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                    class="text-center"
                >
                  TUTAR
                </th>
                <th
                    scope="col"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                    class="text-center"
                >
                  TAH. TÜRÜ
                </th>
                <th
                    scope="col"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                    class="text-center"
                >
                  İŞLEM SAHİBİ
                </th>
                <th
                    scope="col"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                    class="text-center"
                >
                  İŞLEM TARİHİ
                </th>
                <th
                    scope="col"
                    :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"
                    class="text-center"
                />
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(tahsilat, index) in chooseRezervasyon.tahsilatList"
                  :key="index"
              >
                <td class="text-center">{{ tahsilat.tutar }} TL</td>
                <td class="text-center">
                  {{ tahTuru(tahsilat) }}
                </td>
                <td class="text-center">{{ tahsilat.userName }}</td>
                <td class="text-center">{{ tahsilat.kayitTarih.slice(0, 16) }}</td>
                <td class="text-center">
                  <b-button
                      v-if="chooseRezervasyon.checkOut != 1"
                      v-b-tooltip="$store.getters.vardiya.vardiyaID == tahsilat.vardiyaID ?'':'Geçmiş Vardiyaya Ait Tahsilat Kayıtları Silinemez'"
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      :style="{cursor : $store.getters.vardiya.vardiyaID != tahsilat.vardiyaID ? 'default':'',opacity : $store.getters.vardiya.vardiyaID != tahsilat.vardiyaID ? '0.5':''}"
                      @click="$store.getters.vardiya.vardiyaID == tahsilat.vardiyaID ? mukerrerTahsilat(tahsilat.hgtahsilatID):''"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <hr>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgDanger"
                class="border-0"
                :style="{backgroundColor:$store.getters.bgDanger,color:'white'}"
                @click="$bvModal.hide('tahsilat-kayit')"
            >
              Kapat
            </b-button>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
    <b-modal
        id="yeni-rezervasyon"
        no-close-on-backdrop
        centered
        header-bg-variant="white"
        size="lg"
        hide-footer
    >
      <template #modal-header>
        <div class="w-100 d-block mt-3">
          <h1 class="text-center">
            Yeni Rezervasyon
          </h1>
        </div>
      </template>
      <div class="oda-durum-modal">
        <b-form class="p-3">
          <b-row>
            <b-col cols="6">
              <b-form-group label="İsim">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.adi"
                    disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Soy İsim">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.soyAdi"
                    disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Giriş Tarihi">
                <flat-pickr
                    v-model="chooseRezervasyon.girisTarih"
                    class="form-control"
                    :placeholder="chooseRezervasyon.girisTarih"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Çıkış Tarihi">
                <flat-pickr
                    v-model="chooseRezervasyon.cikisTarih"
                    class="form-control"
                    :placeholder="chooseRezervasyon.cikisTarih"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Oda">
                <v-select
                    v-model="konukEkleOdaKodu"
                    label="odaKodu"
                    :options="$store.state.rezervasyon.rezervasyonOdaListesi"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Tarife">
                <b-form-select
                    v-model="chooseRezervasyon.tarife"
                    :options="tarifeOps"
                    value-field="tarifeID"
                    text-field="tarifeAdi"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Rezervasyon Türü">
                <b-form-select
                    v-model="addRezervasyon.rezervasyon"
                    :options="addRezervasyon.reservation"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Ücret">
                <b-form-select
                    v-model="newKonukOdaKapatma"
                    :options="newKonukOdaKapatmaOp"
                    class="mb-3"
                >
                  <!-- <b-form-select-option value="0">Hayır</b-form-select-option>
                                    <b-form-select-option value="1">Evet</b-form-select-option> -->
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Ücret">
                <b-form-input
                    id="basicInput"
                    v-model="chooseRezervasyon.fiyat"
                    v-numericOnly
                    autocomplete="off"
                    step="0.0001"
                    type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                class="text-center mt-1"
            >
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgPrimary"
                  class="mr-50"
                  @click="yeniKonuk"
              >
                Yeni Rezervasyon
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgDanger"
                  class="border-0"
                  :style="{backgroundColor:$store.getters.bgDanger,color:'white'}"
                  @click="$bvModal.hide('yeni-rezervasyon')"
              >
                Kapat
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
    <b-modal
        id="mukerrer-kayit"
        ok-title="Değişiklikleri Kaydet"
        title="Eminmisiniz?"
        cancel-variant="outline-secondary"
        centered
        hide-footer
        cancel-title="Kapat"
        size="sm"
        @ok="konukGuncelle"
    >
      <h1 class="text-center p-2">
        Rezervasyan kaydı silinecektir.
      </h1>
      <p class="text-center">
        Bu işlem geri alınamaz!
      </p>
      <hr>
      <b-row style="justify-content: space-evenly">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            @click="kaydiSil"
        >
          Evet, Kaydı Sil!
        </b-button>
        <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            @click="$bvModal.hide('mukerrer-kayit')"
        >
          İptal
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
        id="giris-tarih"
        ok-title="Değişiklikleri Kaydet"
        title="CheckIN Tarihi Düzenle"
        cancel-variant="outline-secondary"
        centered
        hide-footer
        cancel-title="Kapat"
        size="sm"
    >
      <b-form-group label="Yeni CheckIN Tarihi">
        <flat-pickr
            v-model="chooseRezervasyon.girisTarih"
            class="form-control"
            :placeholder="chooseRezervasyon.girisTarih"
        />
      </b-form-group>
      <hr>
      <b-row style="justify-content: space-evenly">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            class="border-0"
            :style="{backgroundColor:$store.getters.bgSuccess}"
            @click="girisTarihUpdate"
        >
          Güncelle!
        </b-button>
        <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="border-0 text-white"
            :style="{backgroundColor:$store.getters.bgDanger}"
            @click="$bvModal.hide('giris-tarih')"
        >
          İptal
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
        id="cikis-tarih"
        ok-title="Değişiklikleri Kaydet"
        title="Check Out Tarihi Düzenle"
        cancel-variant="outline-secondary"
        centered
        hide-footer
        size="sm"
    >
      <b-form-group label="Yeni CheckOut Tarihi">
        <flat-pickr
            v-model="chooseRezervasyon.cikisTarih"
            class="form-control"
            :placeholder="chooseRezervasyon.cikisTarih"
        />
      </b-form-group>
      <hr>
      <b-row style="justify-content: space-evenly">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            class="border-0"
            :style="{backgroundColor:$store.getters.bgSuccess}"
            @click="cikisTarihUpdate"
        >
          Güncelle!
        </b-button>
        <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="border-0 text-white"
            :style="{backgroundColor:$store.getters.bgDanger}"
            @click="$bvModal.hide('cikis-tarih')"
        >
          İptal
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
        id="toplam-fiyat-guncelle"
        ok-title="Değişiklikleri Kaydet"
        title="Toplam Fiyatı Düzenle"
        cancel-variant="outline-secondary"
        centered
        hide-footer
        size="sm"
    >
      <b-form-group label="Toplam Fiyat">
        <b-form-input
            id="basicInput"
            v-model="chooseRezervasyon.toplamFiyat"
            v-numericOnly
            step="0.0001"
            type="number"
            :placeholder="chooseRezervasyon.toplamFiyat"
            @keyup.enter="toplamFiyatUpdate"
            autocomplete="off"
        />
      </b-form-group>
      <hr>
      <b-row style="justify-content: space-evenly">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            class="border-0"
            :style="{backgroundColor:$store.getters.bgSuccess}"
            @click="toplamFiyatUpdate"
        >
          Güncelle!
        </b-button>
        <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="danger"
            class="border-0 text-white"
            :style="{backgroundColor:$store.getters.danger}"
            @click="$bvModal.hide('toplam-fiyat-guncelle')"
        >
          İptal
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
        id="fiyat-guncelle"
        ok-title="Değişiklikleri Kaydet"
        title="Ücret Düzenle"
        cancel-variant="outline-secondary"
        centered
        hide-footer
        size="sm"
    >
      <b-form-group label="Günlük Konaklama Ücreti">
        <b-form-input
            id="basicInput"
            v-model="chooseRezervasyon.fiyat"
            v-numericOnly
            step="0.0001"
            type="number"
            :placeholder="chooseRezervasyon.fiyat"
            @keyup.enter="fiyatUpdate"
        />
      </b-form-group>
      <hr>
      <b-row style="justify-content: space-evenly">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            class="border-0"
            :style="{backgroundColor:$store.getters.bgSuccess}"
            @click="fiyatUpdate"
        >
          Güncelle!
        </b-button>
        <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="border-0 text-white"
            :style="{backgroundColor:$store.getters.bgDanger}"
            @click="$bvModal.hide('fiyat-guncelle')"
        >
          İptal
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
        id="tahsilat-kayit-success"
        title="Tahsilat İşlemleri"
        hide-footer
        size="lg"
    >
      <h4 class="text-center">
        Bu rezervasyon ücreti sanal pos sistemi ile banka hesabına
        aktarılmıştır.
      </h4>
    </b-modal>
    <b-modal
        id="check-out"
        title="Check Out Onayı"
        hide-footer
        centered
        size="sm"
    >
      <div v-if="chooseRezervasyon.period <= chooseRezervasyon.aktifKonaklama">
        <p class="text-center mt-2">
          Check Out İşlemini Onaylıyormusunuz?
        </p>
        <hr>
        <b-row style="margin-bottom: 10px">
          <b-col cols="12">
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="float-right"
                @click="$bvModal.hide('check-out')"
            >
              İptal
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                class="float-right mr-50"
                @click="direkCheckOut"
            >
              Check Out
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-if="chooseRezervasyon.period > chooseRezervasyon.aktifKonaklama">
        <p class="text-center mt-2">
          {{ chooseRezervasyon.period - chooseRezervasyon.aktifKonaklama }}
          Gecelik Ücret İadesi Oluşturulsun mu?
        </p>
        <hr>
        <b-row style="margin-bottom: 10px">
          <b-col>
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                size="sm"
                @click="$bvModal.hide('check-out')"
            >
              İptal
            </b-button>
          </b-col>
          <b-col>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                size="sm"
                @click="iadeliCheckOut"
            >
              İadeli Check Out
            </b-button>
          </b-col>
          <b-col>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                size="sm"
                @click="direkCheckOut"
            >
              Direkt Check Out
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
        id="onrez-check-in"
        ok-title="Onayla!"
        title="CheckIn Onayı"
        cancel-variant="outline-secondary"
        centered
        hide-footer
        size="sm"
    >
      <b-row style="justify-content: space-evenly">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="bgInfo"
            @click="onRezCheckIn"
        >
          Onayla!
        </b-button>
        <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            @click="$bvModal.hide('onrez-check-in')"
        >
          İptal
        </b-button>
      </b-row>
    </b-modal>
    <b-modal
        id="hizli-fatura-olustur-konuk"
        ok-only
        ok-title="Git"
        centered
        header-bg-variant="white"
        size="lg"
        hide-footer
        @hide="hizliFaturaHide"
    >
      <b-row class="justify-content-center">
        <h2>Fatura Oluştur</h2>
        <p>
          *Şahıs için TC Kimlik numarası 11 haneli, Tüzel firma Vergi Kimlik
          Numarası 10 haneli olmalıdır.
        </p>
      </b-row>
      <b-row class="justify-content-center p-1">
        <b-col cols="6">
          <b-form-group
              label="Fatura Ünvanı *Tüzel Firmalar İçin Zorunlu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaUnvan"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Faturanın Gideceği E-Posta"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.ePosta"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="İsim * Şahıs için zorunlu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.adi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Soy İsim* Şahıs için zorunlu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.soyAdi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
              label="Vergi Dairesi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.vergiDairesi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
              label="*Vergi Numarasi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.vergiNumarasi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Fatura Adresi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaAdres"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Fatura İl"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaIl"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Fatura İlce"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaIlce"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Telefon"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.telefon"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="7">
          <b-form-group
              label="*Ürün Adı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.urunAdi"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
              label="*KDV Oranı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.kdvOrani"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
              label="*KDV Dahil Fiyat"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.toplamTutar"
                v-numericOnly
                autocomplete="off"
                step="0.0001"
                type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group
              label="Fatura Notu"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.faturaNotu"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
              label="Sipariş No/FişNo"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="hizliFatura.siparisNo"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="4"
            class="align-center"
        >
          <b-form-checkbox
              v-model="hizliFatura.konaklamaVergisi"
              class="custom-control-success"
          >
            Konaklama Vergisi
          </b-form-checkbox>
        </b-col>
        <b-col cols="3"/>
        <b-col
            cols="6"
            class="my-col-row"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="time"
              variant="bgInfo"
              :style="{backgroundColor:$store.getters.bgSuccess}"
              class="border-0"
              @click="hizliFaturaOlustur"
          >
            <b-spinner v-if="time" small/>
            Fatura Oluştur
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-dark"
              @click="hizliFaturaHide"
          >
            İptal
          </b-button>
        </b-col>
        <b-col cols="3"/>
      </b-row>
    </b-modal>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="konaklama-karti"
        :pdf-quality="pdfQuality"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="konaklama-karti"
    >
      <section slot="pdf-content" class=" pl-2 pr-2 font-size-12" ref="konaklama-karti">
        <b-row class="mb-3" style="position:relative; margin: 40px">
          <b-col cols="6" class="text-left">
            <p>Otel Sistem Tarihi : {{ sistemTarihi }}</p>
          </b-col>
          <b-col cols="6" class="text-right">
            <p>Tarih : {{ currentDate }}</p>
          </b-col>
          <b-col cols="12" class="text-center mt-1">
            <h4>{{ kurumUnvan }} VE ASO MÜDÜRLÜĞÜ OTEL BEYANNAMESİ</h4>
          </b-col>
          <b-col cols="12" class="text-center mt-2 font-weight-bold">
            <h5>Konaklama Kartı</h5>
          </b-col>
          <b-col cols="12" class="text-center mt-2">
            <b-row style="border:1px solid black;font-weight: 600">
              <b-col cols="6" style="border-right: 1px solid black;">
                <p class="text-left m-0">Adı Soyadı : {{ `${chooseRezervasyon.adi} ${chooseRezervasyon.soyAdi}` }}</p>
              </b-col>
              <b-col cols="6">
                <p class="text-left m-0">Cinsiyet : {{ chooseRezervasyon.cinsiyet == 1 ? 'Erkek' : 'Kadın' }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-row style="border:1px solid black;border-top: none;font-weight: 600">
              <b-col cols="6" style="border-right: 1px solid black;">
                <p class="text-left m-0">Giriş : {{ chooseRezervasyon.girisTarih }}</p>
              </b-col>
              <b-col cols="6">
                <p class="text-left m-0">Oda No : {{ currentOdaKodu }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-row style="border:1px solid black;border-top: none;font-weight: 600">
              <b-col cols="6" style="border-right: 1px solid black;">
                <p class="text-left m-0">Çıkış : {{ chooseRezervasyon.cikisTarih }}</p>
              </b-col>
              <b-col cols="6">
                <p class="text-left m-0">Tel No : {{ chooseRezervasyon.tel }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-row style="border:1px solid black;border-top: none">
              <b-col cols="12">
                <p class="text-center m-0" style="font-weight: 600">Kişi Bilgileri</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-row style="border:1px solid black;border-top: none;font-weight: 600">
              <b-col cols="6" style="border-right: 1px solid black;">
                <p class="text-left m-0">T.C Kimlik No : {{ chooseRezervasyon.tcKimlik }}</p>
              </b-col>
              <b-col cols="6">
                <p class="text-left m-0">Plaka No : {{ chooseRezervasyon.otoPlaka }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-row style="border:1px solid black;border-top: none">
              <b-col cols="6" style="height: 21px">
                <p class="text-left m-0"></p>
              </b-col>
              <b-col cols="6" style="height: 21px">
                <p class="text-left m-0"></p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="text-center mt-1">
            <b-row>
              <b-col cols="8">
                <ul style="list-style-type: none;padding:0;font-weight: 500">
                  <li>
                    <p class="m-0 text-left" style="font-size: 12px;">1- Otel kartlarına misafir ve yiyecek çıkartılması
                      yasaktır.</p>
                  </li>
                  <li>
                    <p class="m-0 text-left" style="font-size: 12px;">
                      2- Odanızın en geç 11:00'a kadar boşaltılması gerekmektedir.</p>
                  </li>
                  <li>
                    <p class="m-0 text-left" style="font-size: 12px;">
                      3- Odada bırakılan değerli eşyalardan öğretmenevimiz sorumlu değildir.</p>
                  </li>
                  <li>
                    <p class="m-0 text-left" style="font-size: 12px;">
                      4- Otel odalarında sigara içmek kesinlikle yasaktır.</p>
                  </li>
                  <li>
                    <p class="m-0 text-left" style="font-size: 12px;">
                      5- Ücretlere vergi dahildir. Bu Belge müşteri ile kurumumuz arasında sözleşme niteliğinde olup
                      itilaf halinde {{ konukKartSehir }} - {{ konukKartIlce }} ilgili mahkemeleri yetkilidir. </p>
                  </li>
                </ul>
              </b-col>
              <b-col cols="4" style="font-weight: 800">
                <p class="text-left m-0">Adı Soyadı : {{ `${chooseRezervasyon.adi} ${chooseRezervasyon.soyAdi}` }}</p>
                <p class="text-left m-0 mt-1">İmza : </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </section>
    </vue-html2pdf>
  </b-container>
</template>

<script>
import {
  BMedia,
  BMediaBody,
  BMediaAside,
  BCardText,
  BLink,
  BCardTitle,
  BSpinner,
  VBTooltip,
  BRow,
  BCol,
  BContainer,
  BCard,
  BTable,
  BBadge,
  BModal,
  VBModal,
  BFormSelect,
  BFormSelectOption,
  BAvatar,
  BAvatarGroup,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBreadcrumb,
  BButton,
  BButtonGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTabs,
  BTab,
  BCardBody,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import _, { isString } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { useWindowSize } from '@vueuse/core'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VueHtml2pdf from 'vue-html2pdf'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    VueHtml2pdf,
    BMedia,
    BMediaBody,
    BMediaAside,
    BCardText,
    BLink,
    BCardTitle,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BContainer,
    BCard,
    BTable,
    BBadge,
    BModal,
    VBModal,
    vSelect,
    BAvatar,
    BFormSelect,
    BFormSelectOption,
    BAvatarGroup,
    BCardBody,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButtonGroup,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    flatPickr,
    BBreadcrumb,
    BButton,
    BTabs,
    BTab,
    StatisticCardHorizontal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      kurumUnvan: '',
      konukKartSehir: '',
      konukKartIlce: '',
      odaStatus: false,
      time: false,
      spinner: true,
      selected: null,
      newKonukOdaKapatma: null,
      newKonukOdaKapatmaOp: [
        {
          value: '0',
          text: 'Hayır',
        },
        {
          value: '1',
          text: 'Evet',
        },
      ],
      konukEkleOdaKodu: '',
      currentOdaKodu: '',
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      newUserData: [],
      isTc: null,
      isTcUserName: null,
      odaKapatma: true,
      addRezervasyon: {
        isim: '',
        soyIsim: '',
        tcKimlik: '',
        tel: '',
        ucret: '',
        girisTarih: '',
        cikisTarih: '',
        cinsiyet: 'Erkek',
        gender: ['Erkek', 'Kadın'],
        rezervasyon: 'Normal',
        reservation: ['Normal', 'Rezervasyon'],
        tarife: null,
      },
      otherTahsilat: {
        tutar: '',
        fisNo: '',
        tcKimlik: '',
        aciklama: '',
      },
      tahsilat: {
        tutar: '',
        aciklama: '',
        fisNo: '',
        tahsilatTur: [
          {
            title: 'Nakit',
            id: '1',
            tur: 'nakit',
          },
          {
            title: 'Kredi Kartı',
            id: '2',
            tur: 'kredikarti',
          },
          {
            title: 'Havale',
            id: '3',
            tur: 'havale',
          },
          {
            title: 'Cari Kredisi',
            id: '4',
            tur: 'kredi',
          },
        ],
        chooseTur: 'nakit',
      },
      konukDegistir: {
        adi: '',
        soyAdi: '',
        tcKimlik: '',
        tel: '',
        otoPlaka: '',
        gender: ['Erkek', 'Kadın'],
        cinsiyet: 'Erkek',
        tarife: [],
      },
      gender: ['Erkek', 'Kadın'],
      cinsiyet: 'Erkek',
      tarife: [],
      chooseRezervasyon: [],
      odaSelect: null,
      rezervasyonNo: this.$route.params.id,
      totalTahsilat: 0,
      hizliFatura: {
        hguestID: null,
        konaklamaVergisi: true,
        siparisNo: '',
        faturaUnvan: '',
        ePosta: '',
        adi: '',
        soyAdi: '',
        vergiDairesi: '',
        vergiNumarasi: '',
        faturaAdres: '',
        faturaIl: '',
        faturaIlce: '',
        urunAdi: '',
        kdvOrani: 10,
        toplamTutar: '',
        faturaNotu: '',
        telefon: '',
      },
    }
  },
  computed: {
    sistemTarihi() {
      return localStorage.getItem('otelWorkDay')
    },
    currentDate() {
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()
      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm
      const formattedToday = dd + '-' + mm + '-' + yyyy
      return formattedToday
    },
    devirControl() {
      return localStorage.getItem('otelFirmaKodu') == '20196001'
    },
    tarifeOps() {
      return Object.values(this.$store.getters.tarife)
    },
    salaryListItems() {
      return _.orderBy(this.$store.getters.getRezList, 'cikisTarih', 'asc')
    },
    odaMessage: {
      get() {
        return this.$store.state.rezervasyon.rezervasyonBilgileri.aciklama
      },
      set(val) {
        this.$store
            .dispatch('odaMessage', {
              rezervasyonNo: this.rezervasyonNo,
              aciklama: val,
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            })
      },
    },
  },
  watch: {
    addRezervasyon: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.tcKimlik) {
          this.$store
              .dispatch('guestSorgu', {
                term: newVal.tcKimlik,
              })
              .then(res => {
                this.isTc = true
                setTimeout(() => {
                  this.isTc = null
                }, 8000)
                this.isTcUserName = `${res[0].adi} ${res[0].soyadi} / ${res[0].tckimlik}`
                this.newUserData = res
              })
              .catch(err => {
                this.isTc = null
              })
        } else {
          this.isTc = null
        }
      },
    },
    '$route.params.id': function (newVal) {
      this.$store.dispatch('rezervasyonGo', newVal)
          .then(res => {
            this.rezervasyonNo = res.data.rezervasyonBilgileri.rezervasyonNo
            this.currentOdaKodu = res.data.rezervasyonBilgileri.odaKodu
                || this.$store.state.rezervasyon.rezervasyonBilgileri.odaKodu
          })
    },
    chooseRezervasyon(newVal) {
      this.cinsiyet = newVal.cinsiyet == 1
          ? 'Erkek'
          : '' || newVal.cinsiyet == 2
              ? 'Kadın'
              : ''
    },
  },
  beforeDestroy() {
    this.$store.commit('clearRezervasyon')
  },
  created() {
    this.konukDegistir.tarife = Object.values(this.$store.getters.tarife)[0]?.tarifeID || localStorage.getItem('otelFirstTarifeID')
    this.addRezervasyon.tarife = Object.values(this.$store.getters.tarife)[0]?.tarifeID || localStorage.getItem('otelFirstTarifeID')
    this.chooseRezervasyon.tarife = Object.values(this.$store.getters.tarife)[0]?.tarifeID || localStorage.getItem('otelFirstTarifeID')
    this.$store
        .dispatch('rezervasyonGo', this.$route.params.id)
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.odaStatus = res.data.rezervasyonBilgileri.odaKapatma == 1
          this.spinner = false
          this.odaSelect = this.$store.state.rezervasyon.odaListesi.find(a => a.odaKodu == res.data.rezervasyonBilgileri.odaKodu)
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
          this.currentOdaKodu = res.data.rezervasyonBilgileri.odaKodu
              || this.$store.state.rezervasyon.rezervasyonBilgileri.odaKodu
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  methods: {
    faturaShow(faturaID) {
      this.$store
          .dispatch('faturaDetay', { id: faturaID })
          .then(res => {
            const Y = '<html>'
            const X = res
            const Z = X.slice(X.indexOf(Y) + Y.length)
            const myWindow = window.open(
                'about:blank',
                'print_popup',
                'width=1000,height=800',
            )
            myWindow.document.write(Z)
            myWindow.print()
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    kartOlustur(res) {
      this.$store
          .dispatch('sistemAyar')
          .then(response => {
            this.kurumUnvan = response.ayarlar.unvan
            this.konukKartSehir = response.ayarlar.il
            this.konukKartIlce = response.ayarlar.ilce
          })
          .finally(() => {
            this.rezEvident(res)
            this.download('konaklama-karti')
          })
    },
    download(ref) {
      this.$refs[ref].generatePdf()
    },
    odaStatusChange(newVal) {
      this.$store
          .dispatch('odaStatusChange', {
            rezervasyonNo: this.rezervasyonNo,
            odaKapatmaSet: newVal,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    hizliFaturaOlustur() {
      this.time = true
      this.$store
          .dispatch('hizliFaturaOlustur', {
            siparisNo: this.hizliFatura.siparisNo,
            faturaUnvan: this.hizliFatura.faturaUnvan,
            ePosta: this.hizliFatura.ePosta,
            adi: this.hizliFatura.adi,
            soyAdi: this.hizliFatura.soyAdi,
            vergiDairesi: this.hizliFatura.vergiDairesi,
            vergiNumarasi: this.hizliFatura.vergiNumarasi.replace(/\s/g, ''),
            faturaAdres: this.hizliFatura.faturaAdres,
            faturaIl: this.hizliFatura.faturaIl,
            faturaIlce: this.hizliFatura.faturaIlce,
            urunAdi: this.hizliFatura.urunAdi,
            kdvOrani: this.hizliFatura.kdvOrani,
            toplamTutar: this.hizliFatura.toplamTutar,
            faturaNotu: this.hizliFatura.faturaNotu,
            konaklamaVergisi: this.hizliFatura.konaklamaVergisi == true ? 1 : 0,
            telefon: this.hizliFatura.telefon,
            hguestID: this.hizliFatura.hguestID,
          })
          .then(res => {
            if (res.result) {
              if (res.result.status == 200) {
                this.hizliFatura.konaklamaVergisi = false
                if (this.$route.name == 'faturaKayitlari') {
                  this.$store.dispatch('faturaList')
                }
                this.$bvModal.hide('hizli-fatura-olustur-konuk')
                this.$swal({
                  title: 'Başarılı',
                  text: this.$store.getters.notificationSettings(res) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
                  icon: 'success',
                  confirmButtonText: 'Kapat',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })

                this.$store
                    .dispatch('rezervasyonGo', this.$route.params.id)
                    .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                      this.odaStatus = res.data.rezervasyonBilgileri.odaKapatma == 1
                      this.spinner = false
                      this.odaSelect = this.$store.state.rezervasyon.odaListesi.find(a => a.odaKodu == res.data.rezervasyonBilgileri.odaKodu)
                      const mesaj = this.$store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast(
                            {
                              component: ToastificationContent,
                              props: {
                                title: 'Başarılı',
                                text: mesaj,
                                icon: 'ThumbsUpIcon',
                                variant: 'success',
                              },
                            },
                            { position },
                        )
                      }
                      this.currentOdaKodu = res.data.rezervasyonBilgileri.odaKodu
                          || this.$store.state.rezervasyon.rezervasyonBilgileri.odaKodu
                    })
                    .catch(err => {
                      this.spinner = false
                      const mesaj = this.$store.getters.notificationSettings(err)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    })


              } else {
                this.$swal({
                  title: 'Hata!',
                  text: this.$store.getters.notificationSettings(res) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
                  icon: 'error',
                  confirmButtonText: 'Kapat',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            } else {
              this.$swal({
                title: 'Hata!',
                text: ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
                icon: 'error',
                confirmButtonText: 'Kapat',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              // this.$bvModal.hide('hizli-fatura-olustur-konuk')
            }
          })
          .catch(err => {
            this.$swal({
              title: 'Hata!',
              text: this.$store.getters.notificationSettings(err) || ' Entegrator Web Servis Hatası, lütfen Entegrator Fatura Panelinden faturanızı kontrol ediniz !',
              icon: 'error',
              confirmButtonText: 'Kapat',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .finally(() => {
            setTimeout(() => {
              this.time = false
            }, 3000)
          })
    },
    faturaModal(rez) {
      this.hizliFatura.hguestID = rez.hguestID
      this.hizliFatura.adi = rez.adi
      this.hizliFatura.soyAdi = rez.soyAdi
      this.hizliFatura.urunAdi = 'Konaklama Hizmet Bedeli'
      this.hizliFatura.toplamTutar = rez.toplamFiyatInt
      this.hizliFatura.vergiNumarasi = rez.tcKimlik
      this.hizliFatura.telefon = rez.tel
      this.hizliFatura.kdvOrani = 10
      this.hizliFatura.faturaNotu = `${rez.adi} ${rez.soyAdi} #${this.$route.params.id} Rezervasyon Numaralı konaklama faturası (Oda Kodu : ${this.currentOdaKodu}) (${rez.girisTarih} - ${rez.cikisTarih})`
      this.$bvModal.show('hizli-fatura-olustur-konuk')
    },
    hizliFaturaHide() {
      this.$bvModal.hide('hizli-fatura-olustur-konuk')
      this.hizliFatura.faturaUnvan = ''
      this.hizliFatura.ePosta = ''
      this.hizliFatura.adi = ''
      this.hizliFatura.soyAdi = ''
      this.hizliFatura.vergiDairesi = ''
      this.hizliFatura.vergiNumarasi = ''
      this.hizliFatura.faturaAdres = ''
      this.hizliFatura.faturaIl = ''
      this.hizliFatura.faturaIlce = ''
      this.hizliFatura.urunAdi = ''
      this.hizliFatura.kdvOrani = 10
      this.hizliFatura.toplamTutar = ''
      this.hizliFatura.faturaNotu = ''
      this.hizliFatura.siparisNo = ''
    },
    rezEvident(rezervasyon) {
      this.chooseRezervasyon.tarife = this.tarifeOps.find(obj => obj.tarife == rezervasyon.tarife)
      this.konukEkleOdaKodu = this.currentOdaKodu
      this.chooseRezervasyon = rezervasyon
      if (this.chooseRezervasyon.tahsilatList) {
        let toplamTutar = 0
        const veri = Object.values(rezervasyon.tahsilatList)
        veri.forEach(element => {
          toplamTutar = parseInt(element.tutar) + parseInt(toplamTutar)
        })
        this.totalTahsilat = toplamTutar
      }
    },
    rezGo(id) {
      this.$router.push(`/rezervasyon/${id.rezervasyonNo}`)
    },
    newUserAtama() {
      this.isTc = null
      this.isTcUserName = null
      this.addRezervasyon.isim = this.newUserData[0].adi
      this.addRezervasyon.soyIsim = this.newUserData[0].soyadi
      this.addRezervasyon.tcKimlik = this.newUserData[0].tckimlik
      this.addRezervasyon.tel = this.newUserData[0].tel
      this.addRezervasyon.cinsiyet = this.newUserData[0].cinsiyet == 1 ? 'Erkek' : 'Kadın'
    },
    backCheckOut(rez) {
      this.$store
          .dispatch('backCheckOut', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: rez.hguestID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store.dispatch('bildirimCek')
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    onRezCheckIn() {
      this.$store
          .dispatch('onRezCheckIn', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: this.chooseRezervasyon.hguestID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store.dispatch('bildirimCek')
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.$bvModal.hide('onrez-check-in')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    iadeliCheckOut() {
      this.$store
          .dispatch('iadeliCheckOut', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: this.chooseRezervasyon.hguestID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store.dispatch('bildirimCek')
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı.',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.$bvModal.hide('check-out')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    direkCheckOut() {
      this.$store
          .dispatch('direkCheckOut', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: this.chooseRezervasyon.hguestID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$store.dispatch('bildirimCek')
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.$bvModal.hide('check-out')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    toplamFiyatUpdate() {
      let totalPrice = this.chooseRezervasyon.toplamFiyat
      totalPrice = totalPrice.replace(',', '.')
      this.$store
          .dispatch('toplamFiyatUpdate', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: this.chooseRezervasyon.hguestID,
            toplamUcret: totalPrice,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.$bvModal.hide('toplam-fiyat-guncelle')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    fiyatUpdate() {
      let price = this.chooseRezervasyon.fiyat
      price = price.replace(',', '.')
      this.$store
          .dispatch('fiyatUpdate', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: this.chooseRezervasyon.hguestID,
            fiyat: price,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.$bvModal.hide('fiyat-guncelle')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    cikisTarihUpdate() {
      this.$store
          .dispatch('cikisTarihUpdate', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: this.chooseRezervasyon.hguestID,
            cikisTarih: this.chooseRezervasyon.cikisTarih,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store.dispatch('bildirimCek')
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.$bvModal.hide('cikis-tarih')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    girisTarihUpdate() {
      this.$store
          .dispatch('girisTarihUpdate', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: this.chooseRezervasyon.hguestID,
            girisTarih: this.chooseRezervasyon.girisTarih,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store.dispatch('bildirimCek')
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.$bvModal.hide('giris-tarih')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    yeniKonuk() {
      this.$store
          .dispatch('yeniKonuk', {
            rezervasyonNo: this.rezervasyonNo,
            odaKapatmaSet: 0,
            adi: this.addRezervasyon.isim,
            soyAdi: this.addRezervasyon.soyIsim,
            tcKimlik: this.addRezervasyon.tcKimlik,
            telefon: this.addRezervasyon.tel,
            cinsiyet: this.addRezervasyon.cinsiyet,
            onRezervasyon: this.addRezervasyon.rezervasyon,
            tarife: this.addRezervasyon.tarife,
            fiyat: this.addRezervasyon.ucret,
            girisTarih: this.addRezervasyon.girisTarih,
            cikisTarih: this.addRezervasyon.cikisTarih,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.addRezervasyon.isim = ''
            this.addRezervasyon.soyIsim = ''
            this.addRezervasyon.tcKimlik = ''
            this.addRezervasyon.tel = ''
            this.addRezervasyon.cinsiyet = 'Erkek'
            this.addRezervasyon.rezervasyon = 'Normal'
            this.addRezervasyon.ucret = ''
            this.addRezervasyon.girisTarih = ''
            this.addRezervasyon.cikisTarih = ''
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    konukKayit() {
      if (this.addRezervasyon.tarife) {
        this.$store
            .dispatch('konukKayit', {
              rezervasyonNo: this.rezervasyonNo,
              odaKapatmaSet: 0,
              adi: this.addRezervasyon.isim,
              soyAdi: this.addRezervasyon.soyIsim,
              tcKimlik: this.addRezervasyon.tcKimlik,
              telefon: this.addRezervasyon.tel,
              cinsiyet: this.addRezervasyon.cinsiyet,
              onRezervasyon: this.addRezervasyon.rezervasyon,
              tarife: this.addRezervasyon.tarife || Object.values(this.$store.getters.tarife)[0].tarifeID,
              fiyat: this.addRezervasyon.ucret,
              girisTarih: this.addRezervasyon.girisTarih,
              cikisTarih: this.addRezervasyon.cikisTarih,
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
              this.addRezervasyon.isim = ''
              this.addRezervasyon.soyIsim = ''
              this.addRezervasyon.tcKimlik = ''
              this.addRezervasyon.tel = ''
              this.addRezervasyon.cinsiyet = 'Erkek'
              this.addRezervasyon.rezervasyon = 'Normal'
              this.addRezervasyon.ucret = ''
              this.addRezervasyon.girisTarih = ''
              this.addRezervasyon.cikisTarih = ''
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: 'Lütfen tarife seçimi yapınız.',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    kaydiSil() {
      this.$store
          .dispatch('kaydiSil', {
            rezervasyonNo: this.rezervasyonNo,
            hguestID: this.chooseRezervasyon.hguestID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status == 200) {
              this.$store.dispatch('bildirimCek')
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
              this.$bvModal.hide('mukerrer-kayit')
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    tahsilatYapBaskaHesap() {
      this.$store
          .dispatch('tahsilatYapBaskaHesap', {
            rezervasyonNo: this.rezervasyonNo,
            tahsilatID: this.hguestID,
            tcKimlik: this.otherTahsilat.tcKimlik,
            tutar: this.otherTahsilat.tutar,
            fisNo: this.otherTahsilat.fisNo,
            hguestID: this.chooseRezervasyon.hguestID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status == 200) {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
              this.$bvModal.hide('tahsilat-kayit')
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    tahsilatYap() {
      this.$store
          .dispatch('tahsilatYap', {
            rezervasyonNo: this.rezervasyonNo,
            tahsilatID: this.hguestID,
            odemeTuru: this.tahsilat.chooseTur,
            tutar: this.tahsilat.tutar,
            fisNo: this.tahsilat.fisNo,
            hguestID: this.chooseRezervasyon.hguestID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status == 200) {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
              this.$bvModal.hide('tahsilat-kayit')
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    mukerrerTahsilat(tahId) {
      const konukId = this.chooseRezervasyon.hguestID
      this.$store
          .dispatch('mukerrerTahsilat', {
            rezervasyonNo: this.rezervasyonNo,
            tahsilatID: tahId,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status == 200) {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
              const veri = Object.values(res.data.rezervasyonKonukListesi)
              const a = veri.find(obj => obj.hguestID == konukId)
              this.chooseRezervasyon = a
              if (a.tahsilatList) {
                let toplamTutar = 0
                const tahList = Object.values(a.tahsilatList)
                tahList.forEach(element => {
                  toplamTutar = parseInt(element.tutar) + parseInt(toplamTutar)
                })
                this.totalTahsilat = toplamTutar
              } else {
                this.totalTahsilat = 0
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    tahTuru(tah) {
      return tah.girisTuru == 'kredikarti'
          ? 'Kredi Kartı'
          : '' || tah.girisTuru == 'nakit'
              ? 'Nakit Satış'
              : '' || tah.girisTuru == 'havale'
                  ? 'Banka Havalesi'
                  : '' || tah.girisTuru == 'kredi'
                      ? 'Kredili Satış'
                      : ''
    },
    tahsilatHide() {
      this.totalTahsilat = 0
      this.otherTahsilat.tutar = ''
      this.otherTahsilat.aciklama = ''
      this.otherTahsilat.fisNo = ''
      this.otherTahsilat.tcKimlik = ''
      this.tahsilat.tutar = ''
      this.tahsilat.aciklama = ''
      this.tahsilat.fisNo = ''
      this.tahsilat.tcKimlik = ''
      this.chooseTur = 'nakit'
    },
    modalChangeHide() {
      (this.konukDegistir.adi = ''),
          (this.konukDegistir.soyAdi = ''),
          (this.konukDegistir.tcKimlik = ''),
          (this.konukDegistir.cinsiyet = 'Erkek'),
          (this.konukDegistir.tarife = Object.values(this.$store.getters.tarife)[0]?.tarifeID || localStorage.getItem('otelFirstTarifeID')),
          (this.konukDegistir.tel = ''),
          (this.konukDegistir.otoPlaka = ''),
          (this.chooseRezervasyon.hguestID = '')
    },
    konukChange() {
      this.$store
          .dispatch('konukChange', {
            adi: this.konukDegistir.adi,
            soyAdi: this.konukDegistir.soyAdi,
            tcKimlik: this.konukDegistir.tcKimlik,
            cinsiyet: this.konukDegistir.cinsiyet,
            telefon: this.konukDegistir.tel,
            otoPlaka: this.konukDegistir.otoPlaka,
            hguestID: this.chooseRezervasyon.hguestID,
            rezervasyonNo: this.rezervasyonNo,
            tarife: this.konukDegistir.tarife,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status == 200) {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    konukGuncelle() {
      this.$store
          .dispatch('konukUpdate', {
            adi: this.chooseRezervasyon.adi,
            soyAdi: this.chooseRezervasyon.soyAdi,
            tcKimlik: this.chooseRezervasyon.tcKimlik,
            cinsiyet: this.cinsiyet,
            telefon: this.chooseRezervasyon.tel,
            otoPlaka: this.chooseRezervasyon.otoPlaka,
            hguestID: this.chooseRezervasyon.hguestID,
            rezervasyonNo: this.rezervasyonNo,
            tarife: this.chooseRezervasyon.tarife,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status == 200) {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '!Hata',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: '!Hata',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    odaDegistir() {
      this.$store
          .dispatch('odaChange', {
            odaSelect: this.odaSelect,
            rezervasyonNo: this.rezervasyonNo,
            chooseRezervasyon: this.chooseRezervasyon,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            if (res.result.status == 200) {
              this.$router.push(`/rezervasyon/${res.result.rezervasyonNo}`)
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.align-center {
  align-items: center !important;
  display: flex !important;
}

.my-col-row {
  justify-content: space-evenly;
  display: flex;
}

.col {
  padding-right: 0 !important;
}

td {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tahsilat-card {
  background-color: #f8f8f5;
  box-shadow: none;
  transition: 500ms;
}

.tahsilat-card:hover {
  transition: 500ms;
  transform: translateY(-10px);
  background-color: #98EECC;
}

.content-wrapper {
  margin-top: -30px;
}

.bv-example-row {
  padding: 0 !important;
}

.card-header button {
  margin-right: 5px !important;
}

.avatar-side-icon {
  color: black;
}

.pull-up {
  transition: 200ms;
  cursor: pointer;
}

.pVerilcek,
.islem-button {
  padding: 10px;
}

.pVerilcekk,
.islem-button {
  padding: 4px;
}

.inputTarih {
  white-space: nowrap;
  z-index: 100 !important;
}

.islem-button-tah-wait {
  background-color: #b3766a !important;
  color: white !important;
}

.table td {
  padding: 0.2rem 0rem;
}

.opacity-none {
  opacity: 1;
}

.card-header fieldset {
  color: red;
  display: inline-block;
  min-width: 90px !important;
  margin-right: 6px;
  margin-bottom: 0;
}

.onRezBadge {
  width: 130px;
  padding: 5px;
  margin-right: 10px;
}

.onRezzBadge {
  width: 120px;
  padding: 5px;
}

.cikisRezBadge {
  width: 90px;
}

.userAdi {
  font-weight: 900;
}

.userAdi p {
  display: inline-block;
  margin-right: 10px;
}

.smallBadge {
  margin-right: 5px;
  font-weight: 500;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
}

.myTelBadge {
  margin-top: 7px !important;
}

.konukIsim {
  margin-bottom: 0 !important;
}

.actionsButton .col-6 {
  padding-right: 0 !important;
  padding-left: 0 !important;
  text-align: center !important;
}

.dropdown button {
  padding: 0.8rem !important;
}

.b-dropdown .btn:not(:disabled):not(.disabled) {
  padding: 0.8rem !important;
}

.guest-mobile-list {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2%;
}
</style>
